import { Color, Group, Path, Point, Matrix } from 'paper';
import { Component } from 'react';

import IncompleteFloorplan from '@models/IncompleteFloorplan';
import { getRandomColor, transformMetersToPixels } from '@dashboard_utils/index';
import { defaultTransformationMatrix } from '../../../consts';
import {
  ICircleFeature,
  IFeatureInfo,
  IGroupFeature,
  IPathFeature,
} from '../../../types';
import { orderLayers } from '../../eventEmitter';
import MapImages from '../../MapImages';
import Paper from '../../Paper';
import { CurrentPickingRouteTask } from '@models/Order';
import { point } from '@turf/turf';

interface IData {
  tasks: CurrentPickingRouteTask[];
  routePath: { path: [number, number][] }[];
  loading: boolean;
}

interface IProps {
  id: string;
  data: IData;
  floorplan: IncompleteFloorplan;
  mapImages: MapImages;
  paper: Paper;
}

class RoutingLayer extends Component<IProps> {
  private elements: (paper.Group | IPathFeature)[] = [];

  public componentDidMount() {
    this.load();
  }

  public componentDidUpdate(prevProps: IProps) {
    const { data } = this.props;
console.log(prevProps.data)
    if (prevProps.data.loading !== data.loading) {
      this.reload();
    }
  }

  public componentWillUnmount() {
    this.clear();
  }

  public reload() {
    this.clear();

    this.load();
  }

  public clear() {
    this.elements.forEach((e) => e.remove());
    this.elements = [];
  }

  public load() {
    const {
      data,
      floorplan,
      mapImages,
      paper,
    } = this.props;

    paper.scope.activate();

    data.tasks
      .forEach((task) => {
        if (task.locationX && task.locationY) {
          const center = new Point(
            transformMetersToPixels(
              [task.locationX, task.locationY || 0],
              floorplan.transformationMatrix || defaultTransformationMatrix,
              floorplan.scale || 1
            )
          );

          const featureInfo: IFeatureInfo = {
            coordinates: [[task.locationX || 0, task.locationY || 0]],
            id: `task_${task.itemId || 'depo'}`,
            title: task.itemName || 'Depo',
            props: {},
            type: 'task',
          };

          const circle1 = new Path.Circle(
            new Point([center.x, center.y]),
            10
          ) as ICircleFeature;
          circle1.fillColor = new Color('white');
          circle1.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));
          circle1.featureInfo = featureInfo;

          const circle2 = new Path.Circle(
            new Point([center.x, center.y]),
            20
          ) as ICircleFeature;
          circle2.fillColor = new Color(task.isDepo ? 'blue' : 'red');
          circle2.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));
          circle2.featureInfo = featureInfo;

          const group = new Group([circle2, circle1]) as IGroupFeature;
          group.featureInfo = featureInfo;

          this.elements.push(group);
        }
      });

    let last: any;
    data.routePath.forEach((routePath) => {
      const routeColor = getRandomColor();
      routePath.path.forEach((point) => {
        const tPoint = new Point(
          transformMetersToPixels(
            [point[0], point[1] || 0],
            floorplan.transformationMatrix || defaultTransformationMatrix,
            floorplan.scale || 1
          )
        );

        if (last) {
          const path = new Path() as IPathFeature;
          path.strokeColor = new Color(routeColor);
          path.strokeWidth = 3
          path.strokeScaling = true;
          path.dashArray = [12, 12];
          path.add(last);
          path.add(new Point(tPoint));
          path.closePath();
          path.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));
          this.elements.push(path);
        }

        last = tPoint;
      });
    });

    orderLayers();
  }

  public render() {
    return null;
  }
}

export default RoutingLayer;
